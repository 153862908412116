.image .attachment-preview .att-link iframe {
  border: none;
  max-width: 220px;
}
._.udu__mult-flex_1 ul.mult-list.horiz {
  display: flex;
  flex-wrap: wrap;
  border: none !important;
}
._.udu__mult-flex_1 ul.mult-list.horiz li.mult-horiz-title {
  width: 100% !important;
  margin-bottom: 5px !important;
  padding: 7px 0 !important;
  border-top: none !important;
  border-bottom: 1px solid #eee;
}
._.udu__mult-flex_1 ul.mult-list.horiz li.mult-horiz-title div {
  background: none !important;
  color: var(--schema-color);
}
._.udu__mult-flex_1 ul.mult-list.horiz .mult-quantity {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 57px;
}
._.udu__mult-flex_1 ul.mult-list.horiz .mult-quantity .mult-inner {
  order: 1;
}
._.udu__mult-flex_1 ul.mult-list.horiz .mult-quantity .option-2 {
  order: 2;
  font-size: 11px;
  text-align: center;
  word-break: break-word;
}
._ ul.product-options.mult-list.horiz .mult-inner input.number {
  width: 40px !important;
}
._ li.udu__mult-opt-mode-Y .udu__mult-opt-info,
._ li.udu__mult-opt-mode-Y .udu__mult-price-info {
  display: inline-block;
}
._ li.udu__mult-opt-mode-Y .udu__mult-price-info span {
  margin-left: 10px;
  white-space: nowrap;
  color: #e21010;
  font-size: 12px;
}
._ li.udu__mult-opt-mode-Y .udu__mult-price-info span.udu__minus {
  color: #269a49;
}
._ li.udu__mult-opt-mode-F .option-2 .udu__mult-price-info,
._ li.udu__mult-opt-mode-F .udu__mult-opt-price-wrapper .udu__mult-price-info {
  font-size: 10px;
  color: #0000ff;
  opacity: 0.001;
  margin-top: 4px;
}
._ li.udu__mult-opt-mode-F .option-2 .udu__mult-price-info span,
._ li.udu__mult-opt-mode-F .udu__mult-opt-price-wrapper .udu__mult-price-info span {
  white-space: nowrap;
  color: #e21010;
  font-size: 9px;
}
._ li.udu__mult-opt-mode-F .option-2 .udu__mult-price-info span.udu__minus,
._ li.udu__mult-opt-mode-F .udu__mult-opt-price-wrapper .udu__mult-price-info span.udu__minus {
  color: #269a49;
}
/*for specific templates corrections - 16 for example*/
.udu__mult-extra-mode-v1 .udu__mult-inv-disp-mode-A .udu__mult-price-info span {
  margin-left: 0 !important;
}
div.submit.mult-ordering {
  text-align: center;
  justify-content: center;
}
.mult-ordering label,
.mult-ordering #pr_form_id_quantity,
.mult-ordering .btn-quant {
  display: none;
}
.mult-ordering .span-submit {
  margin: 0;
}
.mult-ordering > div {
  display: block !important;
}
.mult-list li label {
  font-weight: bold;
}
.mult-list li:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.mult-list li:first-child div.title-2 {
  float: right;
}
.mult-list li:first-child {
  font-weight: bold;
  padding: 10px 10px 10px 0 !important;
}
.mult-list.no-inventory .qty-available,
.mult-list.no-inventory .max-quantity {
  display: none !important;
}
.mult-list li {
  border-bottom: 1px solid #ddd;
  margin: 2px 0 !important;
  min-height: 32px !important;
}
.mult-list li input[type=number]::-webkit-inner-spin-button,
.mult-list li input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mult-list li .mult-options.udu__mult-with-descr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.mult-list li .mult-options.udu__mult-with-descr .option-1 {
  font-weight: bold;
  min-width: 60px;
  text-align: center;
  white-space: nowrap;
}
.mult-list li .mult-options.udu__mult-with-descr .udu__mult-opotion-descr {
  text-align: center;
  flex-grow: 1;
}
.mult-list li .mult-options.udu__mult-with-descr .udu__mult-opotion-descr p {
  color: inherit;
}
.mult-list.only-one-option.no-inventory li {
  height: auto;
}
.mult-list.only-one-option.no-inventory li.option-name > div {
  height: auto;
}
.mult-list.only-one-option li {
  border: none !important;
  display: inline-block;
  vertical-align: top;
  background: none !important;
  box-sizing: border-box;
  min-height: 90px;
}
.mult-list.only-one-option li.option-name > div {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
  padding-top: 4px;
  text-align: right;
}
.mult-list.only-one-option li .option-title {
  display: block;
  font-size: 11px;
  max-width: 80px;
  padding: 3px 2px;
  text-align: center;
  word-break: break-word;
}
.mult-list.only-one-option li .mult-quantity {
  width: auto;
  padding: 3px !important;
}
.mult-list.only-one-option li .max-quantity {
  display: block;
  position: relative;
  top: auto;
  line-height: 11px;
  left: auto;
  right: auto;
  font-size: 11px;
  margin-top: 3px;
  padding: 3px;
}
.mult-list.only-one-option li.opt-title {
  font-weight: bold;
}
.allowBackorder .max-quantity:not(.udu__mult-inv-span).red {
  background-color: #f48c42 !important;
}
.max-quantity:not(.udu__mult-inv-span) {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 20;
  background-color: #15b4d6;
  color: #fff;
  font-size: 10px;
  padding: 2px;
  border-radius: 4px;
  text-align: center;
  min-width: 16px;
  transition: all 0.3s ease-in-out 0s;
}
.max-quantity:not(.udu__mult-inv-span).grayed {
  background-color: #ccc;
}
.max-quantity:not(.udu__mult-inv-span).red {
  background-color: #ce2700;
}
.max-quantity:not(.udu__mult-inv-span).green {
  background-color: #bdda55;
}
.mult-inner {
  position: relative;
}
.mult-inner .mult-input-wrp {
  white-space: nowrap;
}
.mult-quantity {
  float: right;
  padding-right: 10px;
  width: 50px;
  position: relative;
}
.multi-order-comments {
  font-size: 11px;
  padding: 10px;
}
._ .mult-list li.mult-horiz-title:first-child {
  border-top: 0;
}
._ .mult-list li.mult-horiz-title div {
  background: url("https://cdn.uducat.com/static/esb/misc/img/black-dot.png") 4px center no-repeat;
  display: block;
  padding: 2px 5px 2px 10px;
}
._ .mult-list li.mult-horiz-title {
  background: none !important;
  border-top: 1px solid #ddd;
  font-weight: bold;
  min-height: 16px !important;
  padding: 15px 0 2px 0 !important;
}
._ ul.mult-list.horiz {
  border-bottom: 1px solid #ddd;
  text-align: left;
}
._ ul.mult-list.horiz li.option-line label {
  display: none;
}
._ .mult-list.horiz .option-2 {
  margin: 1px 3px;
  text-align: center;
}
._ .mult-list.horiz li.option-line {
  background: none !important;
  border: none;
  display: inline-block;
}
._ .mult-list .is-filled {
  background-color: #ffffd7;
}
._ .mult-quantity input:disabled {
  background-color: #eee !important;
  color: #999 !important;
}
._.mult-qty-with-buttons .mult-quantity input:not([type="radio"]):not([type="file"]):not([type="checkbox"]):not(.custom-input) {
  width: 30px !important;
  text-align: center !important;
}
._ .mult-quantity input:not([type="radio"]):not([type="file"]):not([type="checkbox"]):not(.custom-input) {
  width: 50px !important;
  text-align: center !important;
}
._ .cart-form .submit {
  padding: 10px;
}
.warning {
  color: #cf1300;
}
/*mult-s*/
._ .cart-form li div.label.input-line-title {
  font-weight: bold;
  border: none;
}
._ .mult-s .mult-list li {
  border: none;
  min-height: 15px !important;
  padding: 0;
  background: none !important;
}
._ .mult-s .input-line .mult-quantity {
  float: none;
  white-space: nowrap;
}
._ .cart-form li .input-line label {
  min-width: 50px;
  display: inline-block;
  margin-left: 20px;
  border-bottom: 1px dotted #ccc;
  font-weight: normal;
}
._ .cart-form li .input-line {
  display: block;
}
@media all and (max-width: 760px) {
  ._ .cart-form ul.product-options.mult-list.horiz.only-one-option {
    margin: 0;
  }
  ._ .cart-form ul.product-options.mult-list.horiz.only-one-option li {
    padding: 5px 0;
  }
  ._ .cart-form ul.product-options.mult-list.horiz.only-one-option li:first-child {
    padding: 5px 0 !important;
  }
}
